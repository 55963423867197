<template>
  <step-lesson-layout>
    <template v-slot:head>
      <h1 class="lesson-title">{{ $t('module3.part2.lesson2.title') }}</h1>
      <p class="lesson-title lesson-sub-title">{{ $t('module3.part2.lesson2.subTitle') }}</p>
      <div class="title-annotation">
        <p>{{ $t('module3.part2.lesson2.intro') }}</p>
      </div>
    </template>
    <template v-slot:content>
      <!-- BLOCK1 -->
      <div class="lesson-block">
        <p class="title">{{ $t('module3.part2.lesson2.block1.title') }}</p>
        <div class="conditions-block">
          <div class="conditions">
            <div class="condition">
              <div class="img-wrapper">
                <img :src="require('@/assets/module3/part2/ico-cee-inclus.svg')" :alt="$t('global.imageAlts.ceeIncluIllu')">
              </div>
              <p v-html="$t('module3.part2.lesson2.block1.conditions.cond1')"></p>
            </div>
            <div class="condition">
              <div class="img-wrapper">
                <img :src="require('@/assets/module3/part2/ico-aides-locales.svg')" :alt="$t('global.imageAlts.aidesLocalIllu')">
              </div>
              <p v-html="$t('module3.part2.lesson2.block1.conditions.cond2')"></p>
            </div>
          </div>
        </div>
        <p class="separator"></p>
        <p class="title">{{ $t('module3.part2.lesson2.block1.title2') }}</p>
        <div class="conditions-block">
          <div class="conditions">
            <div class="condition">
              <div class="img-wrapper">
                <img :src="require('@/assets/module3/part2/ico-cee-plus.svg')" :alt="$t('global.imageAlts.ceeCumulIllu')">
              </div>
              <p v-html="$t('module3.part2.lesson2.block1.conditions2.cond1')"></p>
            </div>
            <div class="condition">
              <div class="img-wrapper">
                <img :src="require('@/assets/module3/part2/ico-aides-locales.svg')" :alt="$t('global.imageAlts.aidesLocalIllu')">
              </div>
              <p v-html="$t('module3.part2.lesson2.block1.conditions2.cond2')"></p>
            </div>
          </div>
        </div>
      </div>

      <!-- BLOCK2 -->
      <div class="lesson-block">
        <p class="title">{{ $t('module3.part2.lesson2.block2.title') }}</p>
        <p class="sub-title">{{ $t('module3.part2.lesson2.block2.subTitle') }}</p>
        <p class="paragraphe" v-html="$t('module3.part2.lesson2.block2.content')"></p>
        <p class="separator"></p>
        <p class="sub-title">{{ $t('module3.part2.lesson2.block2.subTitle2') }}</p>
        <div class="flex-wrapper">
          <p class="paragraphe" v-html="$t('module3.part2.lesson2.block2.content2')"></p>
        </div>
        <br>
        <div class="montants">
          <div class="montant">
            <div class="montant-wrapper bleu">
              <div class="mpr">
                <img class="bonus-illu" :src="require('@/assets/module3/part2/logo-mpr-bleu.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                <p>{{ $t('module3.part2.lesson1.mprMenages.tresModeste') }}</p>
              </div>
              <div class="value">
                <p>{{ $t('module3.part2.lesson2.block2.montants.tresModeste') }}</p>
              </div>
            </div>
          </div>
          <div class="montant">
            <div class="montant-wrapper jaune">
              <div class="mpr">
                <img :src="require('@/assets/module3/part2/logo-mpr-jaune.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                <p>{{ $t('module3.part2.lesson1.mprMenages.modeste') }}</p>
              </div>
              <div class="value">
                <p>{{ $t('module3.part2.lesson2.block2.montants.modeste') }}</p>
              </div>
            </div>
          </div>
          <div class="montant">
            <div class="montant-wrapper violet">
              <div class="mpr">
                <img :src="require('@/assets/module3/part2/logo-mpr-violet.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                <p>{{ $t('module3.part2.lesson1.mprMenages.inter') }}</p>
              </div>
              <div class="value">
                <p>{{ $t('module3.part2.lesson2.block2.montants.intermediaire') }}</p>
              </div>
            </div>
          </div>
        </div>
        <br>
        <p class="paragraphe" v-html="$t('module3.part2.lesson2.block2.content3')"></p>
      </div>

      <div class="buttons-wrapper">
        <app-button-layout @click="$emit('next-tab')">{{ $t('global.navigation.resume') }}</app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'Module3Part2Lesson2',
  components: { StepLessonLayout, AppButtonLayout },
  emits: ['next-tab']
}
</script>

<style lang="scss" scoped>
@import "../../../../styles/module3Lesson";

.training-question-content {
  .quizz-activity {
    padding: 0;
    .mpr-question {
      .flex-wrapper {
        img {
          max-width: 100%;
          margin-right: $space-m;
        }
        & + .paragraphe {
          margin-top: $space-sm;
        }
      }
    }
    .answer-wrapper {
      text-align: center;
      color: $c-text;
      padding: $space-xs $space-m;
      min-height: 60px;
      font-weight: $fw-m;
      display: flex;
      align-items: center;
      justify-content: center;
      &.show-answers {
        &.right {
          color: $c-primary;
          font-weight: $fw-l;
        }
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .montants {
    display: grid;
    gap: $space-l $space-xl;
    grid-template-columns: repeat(2, 1fr);
    .montant + .montant {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
    .montant {
      &.bleu, &.jaune {
        padding-bottom: $space-m;
        border-bottom: 2px solid $c-background;
      }
    }
  }
}
</style>

<style lang="scss">
.quizz-activity.m3p2-quizz {
  .quizz-wrapper {
    .answers {
      margin-top: $space-m;
      display: flex;
      flex-direction: column;
      .answer {
        height: auto;
      }
    }
  }
}
</style>
